<template>
  <div class="contackus">
    <nav-bar show-back="1">
      <div class="nav-title">Hubungi kami</div>
    </nav-bar>
  </div>
</template>
<script>
import NavBar from "../../components/navBar";
export default {
  components: { NavBar },
};
</script>
<style scoped lang="scss">
.contackus {
  background-color: #654cff;
  @include bg($img-base, "contackus1.jpg");
  height: 100%;
  background-position: center pxTo(18) !important;
}
// .box{
//   width: 100px;
//   height: 200px;
//   background: chartreuse;
//   &:hover{
//     animation: changecolor 2s  .3s 20  ;
//     // animation-name:changecolor ;
//     // animation-duration: 20s;
//     // animation-delay: .3s;
//     // animation-iteration-count:15;
//   }
//   @keyframes changecolor {
//     0%{
//       background: gold;
//     }
//     20%{
//       background: black;
//     }
//     40%{
//       background: lightcoral;
//       height:500px ;
//     }
//     60%{
//       background: pink;
//       	transform:scale(2);
//     }
//     80%{
//       background: orchid;
//     }
//     100%{
//        background: indianred;
//     }
//   }
// }
</style>